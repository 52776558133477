<template>
  <div id="wrapper">
    <div id="content-wrapper">
      <div id="content">
        <b-container>
          <b-jumbotron header-tag="h3" header-level="5">
            <template #header>
              <span>東海大 臨床研究「特定保健指導積極的支援対象者に対するピアサポート型習慣化アプリによる行動変容の評価のためのランダム化比較試験(PEER-App Study)</span>
            </template>
            <template #lead>
              ご参加者様向けサイト
            </template>
          </b-jumbotron>
        </b-container>
        <router-view></router-view>
        <bffooter></bffooter>
      </div>
    </div>
  </div>
</template>

<script>
import bffooter from '@/components/BfFooter'

export default {
  name: 'Home',
  components: {
    bffooter
  },
  data () {
    return {
      loading: false,
      message: null,
      error: null
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
